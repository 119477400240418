import(/* webpackMode: "eager" */ "/app/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/app/[locale]/layout.tsx\",\"import\":\"DM_Sans\",\"arguments\":[{\"variable\":\"--font-dm_sans\",\"subsets\":[\"latin\"]}],\"variableName\":\"dMSans\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/app/[locale]/layout.tsx\",\"import\":\"Nunito\",\"arguments\":[{\"variable\":\"--font-nunito\",\"subsets\":[\"latin\"]}],\"variableName\":\"nunito\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/local/target.css?{\"path\":\"src/libs/LocalFonts.ts\",\"import\":\"\",\"arguments\":[{\"src\":\"../../public/assets/fonts/DegularDisplay-Semibold.woff\",\"variable\":\"--font-degular-display-semibold\"}],\"variableName\":\"degularDisplaySemibold\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/local/target.css?{\"path\":\"src/libs/LocalFonts.ts\",\"import\":\"\",\"arguments\":[{\"src\":\"../../public/assets/fonts/DegularDisplay-Bold.woff\",\"variable\":\"--font-degular-display-bold\"}],\"variableName\":\"degularDisplayBold\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/local/target.css?{\"path\":\"src/libs/LocalFonts.ts\",\"import\":\"\",\"arguments\":[{\"src\":\"../../public/assets/fonts/DegularDisplay-BlackItalic.woff2\",\"variable\":\"--font-degular-display-black-italic\"}],\"variableName\":\"degularDisplayBlackItalic\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/local/target.css?{\"path\":\"src/libs/LocalFonts.ts\",\"import\":\"\",\"arguments\":[{\"src\":\"../../public/assets/fonts/DegularDisplay-BoldItalic.woff\",\"variable\":\"--font-degular-display-bold-italic\"}],\"variableName\":\"degularDisplayBoldItalic\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/local/target.css?{\"path\":\"src/libs/LocalFonts.ts\",\"import\":\"\",\"arguments\":[{\"src\":\"../../public/assets/fonts/DegularDisplay-Medium.woff\",\"variable\":\"--font-degular-display-medium\"}],\"variableName\":\"degularDisplayMedium\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/local/target.css?{\"path\":\"src/libs/LocalFonts.ts\",\"import\":\"\",\"arguments\":[{\"src\":\"../../public/assets/fonts/DegularDisplay-MediumItalic.woff\",\"variable\":\"--font-degular-display-bold-italic\"}],\"variableName\":\"degularDisplayMediumItalic\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/local/target.css?{\"path\":\"src/libs/LocalFonts.ts\",\"import\":\"\",\"arguments\":[{\"src\":\"../../public/assets/fonts/DegularDisplay-Regular.woff\",\"variable\":\"--font-degular-display-regular\"}],\"variableName\":\"degularDisplayRegular\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/local/target.css?{\"path\":\"src/libs/LocalFonts.ts\",\"import\":\"\",\"arguments\":[{\"src\":\"../../public/assets/fonts/vi/FS-Degular-Display-Bold.otf\",\"variable\":\"--font-degular-display-bold\"}],\"variableName\":\"degularDisplayBoldVi\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/local/target.css?{\"path\":\"src/libs/LocalFonts.ts\",\"import\":\"\",\"arguments\":[{\"src\":\"../../public/assets/fonts/vi/FS-Degular-Display-Semibold.otf\",\"variable\":\"--font-degular-display-semibold\"}],\"variableName\":\"degularDisplaySemiboldVi\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/local/target.css?{\"path\":\"src/libs/LocalFonts.ts\",\"import\":\"\",\"arguments\":[{\"src\":\"../../public/assets/fonts/vi/FS-Degular-Display-Black-Italic.otf\",\"variable\":\"--font-degular-display-black-italic\"}],\"variableName\":\"degularDisplayBlackItalicVi\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/local/target.css?{\"path\":\"src/libs/LocalFonts.ts\",\"import\":\"\",\"arguments\":[{\"src\":\"../../public/assets/fonts/vi/FS-Degular-Display-Bold-Italic.otf\",\"variable\":\"--font-degular-display-bold-italic\"}],\"variableName\":\"degularDisplayBoldItalicVi\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/local/target.css?{\"path\":\"src/libs/LocalFonts.ts\",\"import\":\"\",\"arguments\":[{\"src\":\"../../public/assets/fonts/vi/FS-Degular-Display-Medium.otf\",\"variable\":\"--font-degular-display-medium\"}],\"variableName\":\"degularDisplayMediumVi\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/local/target.css?{\"path\":\"src/libs/LocalFonts.ts\",\"import\":\"\",\"arguments\":[{\"src\":\"../../public/assets/fonts/vi/FS-Degular-Display-Medium-Italic.otf\",\"variable\":\"--font-degular-display-medium-italic\"}],\"variableName\":\"degularDisplayMediumItalicVi\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/local/target.css?{\"path\":\"src/libs/LocalFonts.ts\",\"import\":\"\",\"arguments\":[{\"src\":\"../../public/assets/fonts/vi/FS-Degular-Display.otf\",\"variable\":\"--font-degular-display-regular\"}],\"variableName\":\"degularDisplayRegularVi\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/local/target.css?{\"path\":\"src/libs/LocalFonts.ts\",\"import\":\"\",\"arguments\":[{\"src\":\"../../public/assets/fonts/vi/DMSans-Regular.ttf\",\"variable\":\"--font-dm_sans\"}],\"variableName\":\"dMSansRegular\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/script/EmbeddedScripts.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/smartlook/SmartlookClient.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/app/src/components/ui/toaster.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/styles/global.css");
